<template>
  <div class="equipment-layer">
    <!-- 导航提示标题 -->
    <!-- <ml-tip :showHeader="false" content="设备图层" /> -->
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip equipment-layer-tip" style="margin-bottom: 0">
      <div class="tmap-box" :style="{ height: `${tabberHeight(isFull ? 1 : 90)}px` }">
        <div
          class="tmap-box-aside"
          :class="{
            'tmap-box-aside-isClose': isClose
          }"
        >
          <!-- <div>
            <el-button-group :style="{ display: 'flex', padding: '10px' }">
              <el-button style="flex: 1" type="info" size="small" @click="onClickTab">
                项目图层
              </el-button>
              <el-button style="flex: 1" type="primary" size="small">设备图层</el-button>
            </el-button-group>
          </div> -->
          <div style="padding: 10px 10px 10px">
            <ml-form :model="searchData">
              <!-- 项目名 -->
              <ml-input
                prop="deviceNameLike"
                placeholder="请输入设备名"
                v-model="searchData.deviceNameLike"
              />
              <!-- 搜索按钮 -->
              <ml-button
                v-show="false"
                submitText=""
                submitIcon="el-icon-search"
                :showCancel="false"
                @click-submit="getequipmentTreeList"
              />
            </ml-form>
          </div>
          <el-tree
            :style="{ height: `${tabberHeight(319)}px`, 'overflow-x': 'hidden' }"
            ref="folderTreeRef"
            :data="equipmentTreeList"
            node-key="id"
            accordion
            :default-expanded-keys="defaultCheckedKeys"
            :expand-on-click-node="false"
            @node-click="nodeCLick"
          >
            <template #default="{ node, data }">
              <div :class="`tree-span-${node.level}`" :title="node.label">
                <div class="tree-span-coumt">
                  <div>
                    {{ node.label }}

                    <span v-if="[2, 3, 4].includes(node.level)">
                      ({{ data.num ? data.num : 0 }})
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </el-tree>
          <div class="tmap-box-aside-close" @click="isClose = !isClose">
            <div class="tmap-box-aside-close-btn"></div>
          </div>
        </div>
        <div class="tmap-box-main equipment-main">
          <t-amp
            id="deviceInfosId"
            ref="tMapRef"
            :center="tMapCenter"
            :list="deviceInfosList"
            :tampDeviceId="tampDeviceId"
            @submit-btn="submitBtn"
          />
          <div class="base-map-toggle-box">
            <base-map-toggle @toggle="toggleHandle"></base-map-toggle>
          </div>

          <!-- 底部统计 -->
          <div class="device-type-bar equipment-logs">
            <div class="" v-for="(item, index) in downTabBar" :key="'bar-' + index">
              <div class="image-box" :class="`${item.className}`">
                <img class="image" :src="item.icon" />
              </div>
              <div class="title">{{ item.title }} （{{ item.value }}）</div>
            </div>
          </div>

          <!-- @on-project="onequipmentMap" -->
          <div
            class="table-box"
            :class="{ 'table-box-aside-isTableClose': isTableClose }"
            v-if="showTabel && tabberData.data.length > 0"
          >
            <!-- <i class="el-icon-close table-box-icon" @click="showTabel = false"></i> -->
            <div class="tmap-box-aside-close table-box-close" @click="isTableClose = !isTableClose">
              <div class="tmap-box-aside-close-btn"></div>
            </div>
            <el-table
              :data="tabberData.data"
              style="width: 100%"
              class="deviceInfosTab error-table"
              height="685px"
            >
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="设备名称"
                prop="deviceName"
                width="180px"
              />
              <el-table-column align="center" label="故障等级" prop="severity" width="80px" />
              <!-- <el-table-column align="center" label="所属项目" prop="projectName" min-width="20%" /> -->
              <el-table-column align="center" label="时间" prop="createTime" width="150px" />
              <el-table-column align="center" label="操作" priop="errorId" width="80px">
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="clickOrder(scope.$index, scope.row)"
                    v-if="
                      ['amiba_manager'].includes(userInfo.userType) &&
                      scope.row.isReportOrder === 'Y'
                    "
                  >
                    指派
                  </el-button>
                  <el-button
                    type="success"
                    size="mini"
                    @click="clickDetails(scope.$index, scope.row)"
                    v-else
                  >
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </ml-tip>
    <!-- 设备信息 -->
    <!-- <ml-dialog :title="equipmentInfo.deviceName" width="600px" ref="equipmentInfoDialogRef" :showBtn="false"
      :showClose="true">
      <template #body>
        <div class="equipmentWindow" style="width: 600px">
          <div class="equipmentWindow-title">设备信息</div>
          <div class="equipmentWindow-image" v-if="!!equipmentInfo.imagePath">
            <el-image fit="contain" :style="{ width: '100%', height: '200px' }"
              :src="`${FILEPREVIEWURLs}/${equipmentInfo.imagePath}`" />
          </div>
          <div class="equipmentWindow-body">
            <div>设备类型: {{ equipmentInfo.deviceType }}</div>
          </div>
          <div class="equipmentWindow-body">
            <div>设备位置: {{ equipmentInfo.address }}</div>
          </div>
          <div class="equipmentWindow-body">
            <div>
              设备状态:
              {{
                  equipmentInfo.errorStatus === 'Y'
                    ? '故障'
                    : equipmentInfo.onlineStatus === 'Y'
                      ? '在线'
                      : '离线'
              }}
            </div>
            <div>设备所属阿米巴: {{ equipmentInfo.companyName }}</div>
          </div>
          <div class="equipmentWindow-body equipmentWindow-deviceAttrs" v-if="
            equipmentInfo.deviceAttrs != null &&
            equipmentInfo.deviceAttrs != 'null' &&
            equipmentInfo.deviceAttrs.length > 0
          ">
            <div v-for="item in equipmentInfo.deviceAttrs" :key="item">
              {{ item.propertyName }}: {{ `${item.propertyValue}${item.unit || ''}` }}
            </div>
          </div>
          <div class="equipmentWindow-body equipmentWindow-body-btn">
            <div style="text-align: center; margin: 20px 0">
              <el-button type="primary" @click="clickOrderSb(equipmentInfo)"> 故障上报</el-button>
            </div>
          </div>
        </div>
      </template>
    </ml-dialog> -->
    <!-- 视频 -->
    <!-- <ml-dialog :title="equipmentInfo.deviceName" width="600px" ref="equipmentInfoVideoDialogRef" :showBtn="false"
      :showClose="true">
      <template #body>
        <div class="equipmentWindow" style="width: 600px">
          <div class="equipmentWindow-title">摄像头信息</div>
          <div class="equipmentWindow-body">
            <div class="equipmentWindow-body-video" v-show="equipmentInfo.videoPath">
              <video ref="videoRef" style="width: 540px; height: 200px" autoplay :src="equipmentInfo.videoPath"
                controls></video>
            </div>
            <div class="equipmentWindow-body-video" v-show="!equipmentInfo.videoPath && equipmentInfo.imagePath">
              <el-image fit="contain" :style="{ width: '100%', height: '200px' }"
                :src="`${FILEPREVIEWURLs}/${equipmentInfo.imagePath}`" />
            </div>
          </div>
          <div class="equipmentWindow-body">
            <div>地址: {{ equipmentInfo.videoPath }}</div>
          </div>
          <div class="equipmentWindow-body">
            <div>观测时间: {{ formetData(new Date()) }}</div>
          </div>
          <div class="equipmentWindow-body">
            <div>通讯状态: {{ showVideo && equipmentInfo.videoPath ? '正常' : '异常' }}</div>
          </div>
          <div class="equipmentWindow-body equipmentWindow-body-btn" v-if="!showVideo">
            <div style="text-align: center; margin: 20px 0">
              <el-button type="primary" @click="clickOrderSb(equipmentInfo)"> 故障上报</el-button>
            </div>
          </div>
        </div>
      </template>
    </ml-dialog> -->
    <!-- 指派提示框 -->
    <ml-dialog
      width="600px"
      ref="assignedDialogRef"
      title="指派"
      defaultClose
      showClose
      :showCloseBtn="false"
      @click-submit="assignedDialogClose"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="assignedPeopleTableForm"
          :rules="assignedPeopleTableFormRule"
          ref="assignedPeopleTableFormRef"
        >
          <el-form-item label="指派维修工:">
            <el-radio-group v-model="assignedPeopleTableForm.orderType">
              <el-radio :label="0">所属单位</el-radio>
              <el-radio :label="1">其它单位</el-radio>
            </el-radio-group>
          </el-form-item>

          <amiba-aanager
            v-if="['amiba_manager'].includes(userInfo.userType)"
            :orderType="assignedPeopleTableForm.orderType"
            :multiple="assignedPeopleTableForm.orderType === '0'"
            v-model="repairUsersData"
            v-model:node="repairUsersNode"
          />
          <!-- <el-row>
            <el-col :span="20">
              <el-form-item label="指派维修工:" prop="repairUsers">
                <div class="name-tag-box">
                  <el-tag
                    size="mini"
                    closable
                    type="info"
                    v-for="(item, index) in assignedPeopleTableForm.repairUsers"
                    :key="item"
                    @close="clickTagClose(index)"
                  >
                    {{ item.userName }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="padding: 0 10px">
                <el-button type="text" @click="clickAssignedPeopleAdd">添加</el-button>
              </div>
            </el-col>
          </el-row> -->
          <ml-date-picker
            prop="endTime"
            placeholder="请选择预计完成时间"
            label="预计完成时间:"
            type="datetime"
            v-model="assignedPeopleTableForm.endTime"
          />
          <ml-select
            prop="processId"
            placeholder="请选择工单流程"
            :options="processOption"
            label="工单流程:"
            keyName="processId"
            labelName="processName"
            valueName="processId"
            v-model="assignedPeopleTableForm.processId"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 添加指派人提示框 -->
    <ml-dialog
      width="1200px"
      ref="assignedPeopleDialogRef"
      title="指派人"
      @clickSubmit="clickSubmitAssignedPeople"
      @clickClose="clickSubmitAssignedPeopleClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              style="width: 1160px"
              inline
              labelWidth="80px"
              :model="assignedPeopleForm"
              ref="assignedPeopleFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                :input-style="{ width: '120px' }"
                label="帐号:"
                v-model="assignedPeopleForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                :input-style="{ width: '120px' }"
                label="用户名:"
                v-model="assignedPeopleForm.userNameLike"
              />
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchDataUser"
                @click-submit="searchFnUser"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberDataUser.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="assignedPeopleTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberDataUser.total"
              :page="tabberDataUser.page"
              v-model="tabberDataUser.page"
              :size="tabberDataUser.size"
              @current-change="currentChangeUser"
              @size-change="sizeChangeUser"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
    <!-- 故障申报提示框 -->
    <ml-dialog
      width="600px"
      ref="assignedDialogRefSb"
      title="故障申报"
      defaultClose
      showClose
      @click-submit="assignedDialogSubmitSb"
      @click-close="assignedDialogCloseSb"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="assignedPeopleTableFormSb"
          :rules="assignedPeopleTableFormRuleSb"
          ref="assignedPeopleTableFormRefSb"
        >
          <ml-select
            prop="companyId"
            placeholder="请选择阿米巴"
            :options="unitOptions"
            label="阿米巴:"
            keyName="companyId"
            labelName="companyName"
            valueName="companyId"
            disabled
            v-model="assignedPeopleTableFormSb.companyId"
          />
          <ml-select
            class="assignedPeople-select"
            prop="projectId"
            placeholder="请选择所属项目"
            :options="assignedPeopleProjectDataSb"
            label="所属项目:"
            keyName="projectId"
            labelName="projectName"
            valueName="projectId"
            disabled
            v-model="assignedPeopleTableFormSb.projectId"
          />
          <ml-input
            prop="title"
            label="标题:"
            placeholder="请输入标题"
            v-model.trim="assignedPeopleTableFormSb.title"
          />

          <!-- <ml-select
            prop="errorType"
            placeholder="请选择类型"
            :options="typeData"
            label="类型:"
            labelName="label"
            valueName="label"
            v-model="assignedPeopleTableFormSb.errorType"
          /> -->

          <!-- 设备 -->
          <ml-select
            prop="deviceId"
            placeholder="请选择设备"
            :options="equipmentManageata"
            label="设备:"
            keyName="deviceId"
            labelName="deviceName"
            valueName="deviceId"
            disabled
            v-model="assignedPeopleTableFormSb.deviceId"
            v-model:node="deviceNode"
          />

          <ml-select
            prop="severity"
            placeholder="请选择严重程度"
            :options="severityData"
            label="严重程度:"
            labelName="label"
            valueName="label"
            v-model="assignedPeopleTableFormSb.severity"
          />

          <ml-input
            prop="address"
            label="地点:"
            placeholder="请输入地点"
            v-model.trim="assignedPeopleTableFormSb.address"
          />
          <!-- 流程 -->
          <ml-select
            prop="processData.definitionId"
            placeholder="请选择流程"
            :options="processData"
            label="流程:"
            keyName="definitionId"
            labelName="processName"
            valueName="definitionId"
            v-model="assignedPeopleTableFormSb.processData.definitionId"
            :filterable="true"
            @change="definitionChange"
          />
          <ml-input
            prop="errorDesc"
            label="故障描述:"
            placeholder="请输入故障描述"
            type="textarea"
            :rows="5"
            v-model.trim="assignedPeopleTableFormSb.errorDesc"
          />
          <el-form-item>
            <ml-list fileName="fileName" :list="assignedPeopleTableFormSb.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>

          <el-form-item label="指派维修工:" v-if="['amiba_manager'].includes(userInfo.userType)">
            <el-radio-group v-model="assignedPeopleTableFormSb.orderType">
              <el-radio :label="0">所属单位</el-radio>
              <el-radio :label="1">其它单位</el-radio>
            </el-radio-group>
          </el-form-item>

          <amiba-aanager
            v-if="['amiba_manager'].includes(userInfo.userType)"
            :orderType="assignedPeopleTableFormSb.orderType"
            :multiple="assignedPeopleTableFormSb.orderType === '0'"
            v-model="repairUsersDataSb"
            v-model:node="repairUsersNodeSb"
          />

          <!-- <el-row v-if="['amiba_manager'].includes(userInfo.userType)">
            <el-col :span="20">
              <el-form-item label="指派维修工:">
                <div class="name-tag-box">
                  <el-tag
                    size="mini"
                    closable
                    type="info"
                    v-for="(item, index) in assignedPeopleTableFormSb.repairUsers"
                    :key="item"
                    @close="clickTagCloseSb(index)"
                  >
                    {{ item.userName }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="padding: 0 10px">
                <el-button type="text" @click="clickAssignedPeopleAddSb">添加</el-button>
              </div>
            </el-col>
          </el-row> -->
          <ml-date-picker
            v-if="['amiba_manager'].includes(userInfo.userType)"
            prop="endTime"
            placeholder="请选择预计完成时间"
            label="预计完成时间:"
            type="datetime"
            v-model="assignedPeopleTableFormSb.endTime"
          />
          <ml-select
            v-if="['amiba_manager'].includes(userInfo.userType)"
            prop="processId"
            placeholder="请选择工单流程"
            :options="processOption"
            label="工单流程:"
            keyName="processId"
            labelName="processName"
            valueName="processId"
            v-model="assignedPeopleTableFormSb.processId"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 添加指派人提示框 -->
    <ml-dialog
      width="1200px"
      ref="assignedPeopleDialogRefSb"
      title="指派人"
      @clickSubmit="clickSubmitAssignedPeopleSb"
      @clickClose="clickSubmitAssignedPeopleCloseSb"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              style="width: 1160px"
              inline
              labelWidth="80px"
              :model="assignedPeopleFormSb"
              ref="assignedPeopleFormRefSb"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                :input-style="{ width: '120px' }"
                label="帐号:"
                v-model="assignedPeopleFormSb.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                :input-style="{ width: '120px', marginRight: '40px' }"
                label="用户名:"
                v-model="assignedPeopleFormSb.userNameLike"
              />
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchDataUserSb"
                @click-submit="searchFnUserSb"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberDataUserSb.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAllSb"
              @select="selectSb"
              :row-key="row => row.userId"
              ref="assignedPeopleTableRefSb"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberDataUserSb.total"
              :page="tabberDataUserSb.page"
              v-model="tabberDataUserSb.page"
              :size="tabberDataUserSb.size"
              @current-change="currentChangeUserSb"
              @size-change="sizeChangeUserSb"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { tabberHeight, formetData, getTabberData, searchParams, replacePerCent } from '@utils'
import { ElLoading } from 'element-plus'
import TAmp from '../../components/tamap.vue'
import baseMapToggle from '../../components/baseMapToggle.vue'

import amibaAanager from '../../components/amibaAanager.vue'
import { useStore } from 'vuex'
import { BASEURL, FILEUPLOADURL, FILEPREVIEWURL, tampCityLocaltionKey } from '@API'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default {
  name: 'EquipmentLayer',
  components: {
    TAmp,
    amibaAanager,
    baseMapToggle
  },
  setup(props, content) {
    const isFull = inject('isFull')

    const router = useRouter()
    const tMapCenter = ref([])
    const tMapRef = ref()
    const { commit, dispatch, getters } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const toKen = computed(() => getters.getToken)
    const userInfo = computed(() => getters.getUser)
    const searchData = reactive({
      deviceNameLike: ''
    })
    const tampDeviceId = ref('')
    // 表格配置
    const tabberData = reactive({
      data: []
    })
    const showVideo = ref(false)

    // 底部标签栏
    const downTabBar = reactive([
      {
        icon: require('@/assets/image/map/circle-b.png'),
        title: '正常',
        className: 'png-img',
        name: 'normalNum',
        value: 0
      },
      {
        icon: require('@/assets/image/map/circle-g.png'),
        title: '未接入',
        className: 'png-img',
        name: 'expirationNum',
        value: 0
      },
      {
        icon: require('@/assets/image/map/circle-r.png'),
        title: '故障',
        className: 'png-img',
        name: 'malfunctionNum',
        value: 0
      }
    ])

    // 查询设备图层设备数量统计
    const getDeviceBasesStatisticsVo = () => {
      return dispatch('fetchGetDeviceBasesStatisticsVo').then(res => {
        if (res.code == 200) {
          const result = res.data
          downTabBar.map(item => {
            const val = result[item.name]
            item.value = val
            return item
          })
        }
      })
    }

    // 树配置
    const equipmentTreeList = ref([])
    const defaultCheckedKeys = ref([])
    const defaultParams = ref([])
    // const getFirstId = async (item = {}, parentId = []) => {
    //   if (item.children && item.children.length > 0) {
    //     if (item.type !== 'device') {
    //       parentId = [...parentId, item.id]
    //       if (!['city', 'deviceType'].includes(item.type)) {
    //         defaultParams.value = [...defaultParams.value, item.id]
    //       } else {
    //         defaultParams.value = [...defaultParams.value, item.label]
    //       }
    //     }
    //     return getFirstId(item.children[0], parentId)
    //   } else {
    //     if (item.type !== 'device') {
    //       parentId = [...parentId, item.id]
    //       if (!['city', 'deviceType'].includes(item.type)) {
    //         defaultParams.value = [...defaultParams.value, item.id]
    //       } else {
    //         defaultParams.value = [...defaultParams.value, item.label]
    //       }
    //     }
    //     return parentId
    //     // return item.id ? [item.id] : []
    //   }
    // }
    // 获取设备
    const getDeviceList = async () => {
      deviceInfosList.value = await getDeviceInfos()
    }

    // 获取树
    const folderTreeRef = ref()
    const getequipmentTreeList = () => {
      const params = {}
      if (searchData.deviceNameLike) {
        params.deviceNameLike = replacePerCent(searchData.deviceNameLike)
      }
      dispatch('fetchDeviceTreeList', params).then(async data => {
        if (data && data.code === 200 && data.data.length > 0) {
          // const nData = await data.data.map(item => setFirstData(item))
          // defaultCheckedKeys.value = await getFirstId(data.data[0])
          // const result = data.data.map(item=>{
          //   item.id = item.companyId
          //   item.label = item.companyName
          //   return item
          // })
          // console.log(result)
          defaultCheckedKeys.value = [0]
          equipmentTreeList.value = [
            {
              id: '0',
              label: '全部',
              status: 'N',
              type: 'company',
              children: data.data
            }
          ]
        } else {
          equipmentTreeList.value = []
        }
      })
    }
    watch(
      () => equipmentTreeList.value,
      async newvalue => {
        if (newvalue && newvalue.length > 0) {
          // deviceInfosList.value = await getDeviceInfos({ companyId: newvalue[0].id })
          const params = {}
          let activeTree = ''
          const [companyId, city, projectId, deviceType] = defaultParams.value

          if (companyId) {
            params.companyId = companyId
            activeTree = defaultCheckedKeys.value[0]
          }
          if (city) {
            params.city = city
            activeTree = defaultCheckedKeys.value[1]
          }
          if (projectId) {
            params.projectId = projectId
            activeTree = defaultCheckedKeys.value[2]
          }
          if (deviceType) {
            params.deviceType = deviceType
            activeTree = defaultCheckedKeys.value[3]
          }
          deviceInfosList.value = await getDeviceInfos(params)
          nextTick(() => {
            folderTreeRef.value.setCurrentKey(activeTree)
          })
        } else {
          deviceInfosList.value = []
        }
      }
    )
    // 获取设备
    const deviceInfosList = ref([])
    const getDeviceInfos = async params => {
      const nParams = params || {}
      if (searchData.deviceNameLike) {
        nParams.deviceNameLike = replacePerCent(searchData.deviceNameLike)
      }
      // if (defaultCheckedKeys.value.length > 0) {
      //   nParams.deviceId = defaultCheckedKeys.value[0]
      // }
      clearTimeout(timeOut.value)
      const data = await dispatch('fetchGetDeviceBases', nParams)
      setTimeOutDeviceInfos()
      return data.data || []
    }
    const timeOut = ref()
    const setTimeOutDeviceInfos = () => {
      timeOut.value = setTimeout(async () => {
        if (equipmentInfoNode.value.id) {
          nodeCLick([], equipmentInfoNode.value)
        } else {
          deviceInfosList.value = await getDeviceInfos()
        }
      }, 180000)
    }

    // 获取设备详情
    const getDeviceInfo = async equipmentId => {
      const data = await dispatch('fetchGetDeviceInfo', equipmentId)
      return data.data || []
    }

    // 对话框
    const equipmentInfoDialogRef = ref()
    const equipmentInfoVideoDialogRef = ref()
    const equipmentInfo = ref({})
    const equipmentInfoNode = ref({})
    const cityLocal = ref('')

    // 树点击事件
    const videoRef = ref()
    const nodeCLick = async (data, node) => {
      equipmentInfoNode.value = node
      cityLocal.value = ''
      if (node.level === 1) {
        deviceInfosList.value = await getDeviceInfos()
      } else if (node.level === 2) {
        deviceInfosList.value = await getDeviceInfos({ companyId: node.data.id })

        // 新加 点击阿米巴查询子级数据
        const ambList = equipmentTreeList.value[0].children
        // console.log(ambList)
        const ambIndex = ambList.findIndex(item => item.id == node.data.id)
        // const ambItem = ambList[ambIndex] || {}

        // if (!ambItem.children || !ambItem.children.length) {
        //   // 查询子级
        //   const res = await getAMBChildren(node.data.id)
        //   if (res.code == 200) {
        //     const dataRes = res.data
        //     // console.log(ambIndex)
        //     // console.log(equipmentTreeList.value[ambIndex])
        //     equipmentTreeList.value[0].children[ambIndex] = dataRes
        //     // console.log('更新')
        //     // console.log(node.key)
        //     folderTreeRef.value.updateKeyChildren(node.key, dataRes.children)
        //   }
        // }
        // console.log(child)
        // defaultParams.value = [node.data.id]
      } else if (node.level === 3) {
        const a = `https://restapi.amap.com/v3/geocode/geo?address=${node.data.label}&output=json&key=${tampCityLocaltionKey}`
        const localtion = await axios.get(a)
        const { geocodes } = localtion.data || {}
        tMapCenter.value = geocodes[0].location.split(',')
        deviceInfosList.value = await getDeviceInfos({
          companyId: node.parent.data.id,
          nameLike: node.data.label
        })
        // defaultParams.value = [parent.data.id, node.data.id]
      } else if (node.level === 4) {
        const a = `https://restapi.amap.com/v3/geocode/geo?address=${node.data.label}&output=json&key=${tampCityLocaltionKey}`
        const localtion = await axios.get(a)
        const { geocodes } = localtion.data || {}
        tMapCenter.value = geocodes[0].location.split(',')
        deviceInfosList.value = await getDeviceInfos({
          companyId: node.parent.parent.data.id,
          nameLike: node.data.label
        })
        // defaultParams.value = [parent.data.id, node.data.id]
      }
      //  else if ( node.level === 4) {
      //   deviceInfosList.value = await getDeviceInfos({
      //     companyId: node.parent.parent.data.id,
      //     projectId: node.data.id
      //   })
      //   tabberData.data = await getFaultServiceCenterTabberData({
      //     companyId: node.parent.parent.data.id,
      //     projectId: node.data.id,
      //     searchType: 'layer'
      //   })
      //   showTabel.value = true
      //   // defaultParams.value = [parent.data.id, node.data.id]
      // }
      // else if (node.level === 5) {
      //   deviceInfosList.value = await getDeviceInfos({
      //     companyId: node.parent.parent.parent.data.id,
      //     deviceType: node.data.label,
      //     projectId: node.parent.data.id
      //   })
      // defaultParams.value = [
      //   parent.parent.parent.data.id,
      //   parent.parent.data.id,
      //   parent.data.id,
      //   node.data.id
      // ]
      // }
      else if (node.level === 6 || node.level === 5) {
        if (node.data.id) {
          showVideo.value = false
          deviceInfosList.value = await getDeviceInfos({ deviceId: node.data.id })
          // equipmentInfo.value = await getDeviceInfo(node.data.id)
          equipmentInfo.value = deviceInfosList.value.find(item => item.deviceId == node.data.id)

          const { latitude, longitude } = equipmentInfo.value
          if (latitude && longitude) {
            tMapCenter.value = [longitude, latitude]
          }
          tampDeviceId.value = node.data.id
          setTimeout(() => {
            // 重置
            tampDeviceId.value = ''
          }, 100)
          // if (equipmentInfo.value.videoPath) {
          //   equipmentInfoVideoDialogRef.value.showDialog = true
          //   nextTick(() => {
          //     if (videoRef.value) {
          //       videoRef.value.addEventListener('error', isVideoError)
          //       videoRef.value.addEventListener('play', isVideoSuccess)
          //     }
          //   })
          // } else {
          // equipmentInfoDialogRef.value.showDialog = true
          // }
        }
      }

      // console.log(node.data)
      // 触发定位
      if (node.data.id) {
        // equipmentInfo.value = await getDeviceInfo(node.data.id)
        equipmentInfo.value = deviceInfosList.value.find(item => item.deviceId == node.data.id)
        if (equipmentInfo.value) {
          const { latitude, longitude } = equipmentInfo.value
          if (latitude && longitude) {
            tMapCenter.value = [longitude, latitude]
          }
        } else {
          // 定位
          const first = deviceInfosList.value.find(item => item.latitude && item.longitude) || {}
          const { latitude, longitude } = first

          if (latitude && longitude) {
            tMapCenter.value = [longitude, latitude]
          }
        }
      } else {
        // console.log(deviceInfosList.value)
      }
    }

    // const onequipmentMap = async item => {
    //   // nextTick(() => {
    //   //   if (videoRef.value) {
    //   //     videoRef.value.removeEventListener('error', isVideoError)
    //   //     videoRef.value.removeEventListener('success', isVideoSuccess)
    //   //   }
    //   // })
    //   equipmentInfo.value = await getDeviceInfo(item.deviceId)
    //   if (equipmentInfo.value.videoPath) {
    //     showVideo.value = false
    //     equipmentInfoVideoDialogRef.value.showDialog = true
    //     nextTick(() => {
    //       if (videoRef.value) {
    //         videoRef.value.addEventListener('error', isVideoError)
    //         videoRef.value.addEventListener('play', isVideoSuccess)
    //       }
    //     })
    //   } else {
    //     equipmentInfoDialogRef.value.showDialog = true
    //   }
    // }

    // 根据阿米巴查询数据
    const getAMBChildren = companyId => {
      return dispatch('fetchProjectByCompanyIdDeviceTree', { companyId })
    }

    const showTabel = ref(false)

    // 获取故障列表
    const getFaultServiceCenterTabberData = async (params = {}) => {
      const { data } = await getTabberData(dispatch, 'fetchGetDeviceErrorRecords', params, {
        searchStatus: isBusinessDepartment.value ? 1 : 0
      })
      return data || []
    }

    /** 指派人 开始************************************/
    // 工单流程列表
    const processOption = ref([])
    // 打开指派或通过对话框
    const assignedPeopleTableFormRef = ref()
    const assignedPeopleTableForm = reactive({
      repairUsers: [],
      endTime: '',
      processId: '',
      errorId: '',
      orderType: ''
    })
    const assignedPeopleTableFormRule = {
      repairUsers: [{ required: true, message: '请选择指派人 ', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择预计完成时间 ', trigger: 'blur' }]
    }
    const assignedPeopleTableRef = ref()
    const assignedDialogRef = ref()
    // 打开指派对话框
    const clickOrder = async (index, row) => {
      await getOrderProcesss(row.errorId)
      assignedPeopleTableForm.errorId = row.errorId
      assignedPeopleForm.companyId = row.companyId
      assignedPeopleForm.projectId = row.projectId
      assignedDialogRef.value.showDialog = true
    }
    const clickDetails = async (index, row) => {
      const { errorId } = row
      router.push({ path: '/faultServiceCenterDetails', query: { errorId } })
    }
    // 保存，确认指派
    const assignedDialogClose = () => {
      const actionName = 'fetchAddCreateErrorOrder'
      assignedPeopleTableFormRef.value.CustomFormRef.validate()
        .then(() => {
          const faultServiceCenterParams = searchParams(assignedPeopleTableForm)

          faultServiceCenterParams.errorId = assignedPeopleTableForm.errorId
          dispatch(actionName, faultServiceCenterParams).then(async data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              // showTabel.value = false
              // router.push({ path: '/faultServiceCenter' })
              tabberData.data = await getFaultServiceCenterTabberData({
                companyId: assignedPeopleForm.companyId,
                projectId: assignedPeopleForm.projectId
              })
              assignedDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    // 打开指派人对话框
    const assignedPeopleDialogRef = ref()
    const clickAssignedPeopleAdd = () => {
      selectData.value = saveSelectData.value = []
      getAssignedPeopleData()
      assignedPeopleDialogRef.value.showDialog = true
    }
    // 指派人表单
    const assignedPeopleFormRef = ref()
    // 指派人搜索条件
    const assignedPeopleForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: '',
      projectId: ''
    })
    // 指派人单位配置
    const assignedPeopleProjectData = ref([])
    const getAssignedPeopleProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      assignedPeopleProjectData.value = data || []
    }

    // 监听指派人单位
    watch(
      () => assignedPeopleForm.companyId,
      newvalue => {
        getAssignedPeopleProjectData(newvalue)
      }
    )
    // 删除Tag
    const clickTagClose = index => {
      saveSelectData.value.splice(index, 1)
      selectData.value = []
      assignedPeopleTableRef.value.clearSelection()
    }

    // 查询工单审核流程
    const getOrderProcesss = errorId => {
      dispatch('fetchGetOrderProcesss', { errorId }).then(data => {
        if (data && data.code === 200 && data.data) {
          processOption.value = data.data || []
        }
      })
    }

    /** 指派人 结束************************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberDataUser = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      saveSelectData: [],
      isSubmie: false
    })
    // 选择指派人列表
    const selectData = ref()
    // 保存选择人
    const unitOptions = ref([])
    const saveSelectData = ref([])

    watch(
      () => tabberDataUser.saveSelectData,
      newvalue => {
        saveSelectData.value = newvalue
      }
    )
    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberDataUser.page, pageSize: tabberDataUser.size }
    ) => {
      const searchDataParams = searchParams(assignedPeopleForm, ['companyId', 'projectId'])
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetGanagerEngineers',
        params,
        searchDataParams
      )
      tabberDataUser.data = data || []
      tabberDataUser.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          assignedPeopleTableRef.value.toggleRowSelection(item, true)
        })
      }
    }
    //分页事件
    watch([() => tabberDataUser.page, () => tabberDataUser.size], () => {
      getAssignedPeopleData()
    })
    const currentChangeUser = page => {
      tabberDataUser.page = page
    }
    const sizeChangeUser = size => {
      tabberDataUser.size = size
    }

    // 重置搜索内容
    const resetSearchDataUser = () => {
      assignedPeopleFormRef.value.CustomFormRef.resetFields()
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }
    // 搜索
    const searchFnUser = () => {
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          companyId: item.companyId
        }
      })
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }

    // 提交选中指派人
    const clickSubmitAssignedPeople = () => {
      tabberDataUser.isSubmie = true
      saveSelectData.value = selectData.value
      assignedPeopleTableForm.repairUsers = selectData.value
      assignedPeopleDialogRef.value.showDialog = false
    }
    // 取消选择指派人
    const clickSubmitAssignedPeopleClose = () => {
      if (!tabberDataUser.isSubmie) {
        selectData.value = []
        assignedPeopleTableRef.value.clearSelection()
      }
      tabberDataUser.isSubmie = false
    }

    /* 结束 数据列表 **********************************/

    /**************************************************/
    /**************************************************/
    /**************************************************/
    /**************************************************/
    /**  申报 *****************************************/
    /** 指派人 开始************************************/
    // 工单流程列表
    // 打开指派或通过对话框
    // 设备下拉列表
    // 类型下拉列表
    const typeData = ref([{ label: '故障' }, { label: '缺陷' }])
    // 严重程度下拉列表
    const severityData = ref([
      { label: '轻微' },
      { label: '一般' },
      { label: '严重' },
      { label: '灾难' }
    ])
    const equipmentManageata = ref([])
    // 所属项目下拉列表
    const projectData = ref([])
    // 设备选中信息
    const deviceNode = ref({})
    const assignedPeopleTableFormRefSb = ref()
    const assignedPeopleTableFormSb = reactive({
      companyId: '',
      projectId: '',
      title: '',
      propertyType: '',
      deviceType: '',
      firstUnit: '',
      secondUnit: '',
      deviceId: '',
      severity: '',
      address: '',
      errorDesc: '',
      attachVos: [],
      orderType: '',

      deviceName: '',
      repairUsers: [],
      endTime: '',
      processId: '',
      errorId: '',
      processData: {
        businessId: '',
        definitionKey: '',
        definitionId: '',
        procInsId: '',
        businessType: '',
        processName: ''
      }
    })
    let assignedPeopleTableFormRuleSb = {
      projectId: [{ required: true, message: '请选择项目 ', trigger: 'blur' }],
      title: [{ required: true, message: '请输入标题 ', trigger: 'blur' }],
      deviceId: [{ required: true, message: '请选择设备 ', trigger: 'blur' }],
      severity: [{ required: true, message: '请选择故障程度 ', trigger: 'blur' }],
      address: [{ required: true, message: '请输入地址 ', trigger: 'blur' }],
      'processData.definitionId': [{ required: true, message: '请选择流程 ', trigger: 'blur' }]
    }
    if (['amiba_manager'].includes(userInfo.value.userType)) {
      assignedPeopleTableFormRuleSb = {
        ...assignedPeopleTableFormRuleSb,
        repairUsers: [{ required: true, message: '请选择指派人 ', trigger: 'blur' }],
        endTime: [{ required: true, message: '请选择预计完成时间 ', trigger: 'blur' }]
      }
    }
    if (['division_manager'].includes(userInfo.value.userType)) {
      assignedPeopleTableFormRuleSb = {
        ...assignedPeopleTableFormRuleSb,
        companyId: [{ required: true, message: '请选择阿米巴 ', trigger: 'blur' }]
      }
    }
    const assignedPeopleTableRefSb = ref()
    const assignedDialogRefSb = ref()
    // 打开指派对话框
    const clickOrderSb = async row => {
      if (['amiba_manager'].includes(userInfo.value.userType)) {
        await getOrderProcesss(row.errorId)
      }
      assignedPeopleTableFormSb.companyId = row.companyId
      assignedPeopleTableFormSb.errorId = row.errorId
      assignedPeopleTableFormSb.projectId = row.projectId
      assignedPeopleTableFormSb.propertyType = row.propertyType
      assignedPeopleTableFormSb.deviceType = row.deviceType
      assignedPeopleTableFormSb.firstUnit = row.firstUnit
      assignedPeopleTableFormSb.secondUnit = row.secondUnit
      assignedPeopleTableFormSb.deviceName = row.deviceName
      assignedPeopleTableFormSb.deviceId = row.deviceId
      assignedPeopleTableFormSb.title = ''
      assignedPeopleTableFormSb.errorDesc = ''
      assignedPeopleTableFormSb.address = `${row.province || ''}${row.city || ''}${row.area || ''}${
        row.address
      }`

      assignedPeopleFormSb.companyId = row.companyId
      assignedPeopleFormSb.projectId = row.projectId
      assignedDialogRefSb.value.showDialog = true
    }

    const submitBtn = row => {
      clickOrderSb(row)
    }
    const assignedDialogCloseSb = () => {
      assignedDialogRefSb.value.showDialog = false
    }
    // 保存，确认指派
    const assignedDialogSubmitSb = () => {
      const actionName = 'fetchNewAddDeviceErrorRecord'
      assignedPeopleTableFormRefSb.value.CustomFormRef.validate()
        .then(() => {
          const faultServiceCenterParams = searchParams(assignedPeopleTableFormSb, [
            'endTime',
            'repairUsers',
            'processId'
          ])
          if (['amiba_manager'].includes(userInfo.value.userType)) {
            const nerrorOrderVo = {}
            if (assignedPeopleTableFormSb.endTime) {
              nerrorOrderVo.endTime = formetData(assignedPeopleTableFormSb.endTime)
            }
            if (
              assignedPeopleTableFormSb.repairUsers &&
              assignedPeopleTableFormSb.repairUsers.length > 0
            ) {
              nerrorOrderVo.repairUsers = assignedPeopleTableFormSb.repairUsers
            }
            if (assignedPeopleTableFormSb.processId) {
              nerrorOrderVo.processId = assignedPeopleTableFormSb.processId
            }
            if (Object.keys(nerrorOrderVo).length > 0) {
              nerrorOrderVo.errorId = assignedPeopleTableFormSb.errorId
              nerrorOrderVo.orderType = assignedPeopleTableFormSb.orderType
              faultServiceCenterParams.errorOrderVo = nerrorOrderVo
            }
          }
          faultServiceCenterParams.errorId = assignedPeopleTableFormSb.errorId
          if (deviceNode.value && deviceNode.value.deviceName) {
            faultServiceCenterParams.deviceName = deviceNode.value.deviceName
          }
          dispatch(actionName, faultServiceCenterParams).then(async data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              // showTabel.value = false
              // router.push({ path: '/faultServiceCenter' })
              tabberDataUserSb.data = await getAssignedPeopleProjectDataSB({
                companyId: assignedPeopleFormSb.companyId,
                projectId: assignedPeopleFormSb.projectId
              })
              assignedDialogRefSb.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    // 打开指派人对话框
    const assignedPeopleDialogRefSb = ref()
    const clickAssignedPeopleAddSb = () => {
      selectDataSb.value = saveSelectDataSb.value = []
      getAssignedPeopleDataSb()
      assignedPeopleDialogRefSb.value.showDialog = true
    }
    // 指派人表单
    const assignedPeopleFormRefSb = ref()
    // 指派人搜索条件
    const assignedPeopleFormSb = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: '',
      projectId: ''
    })
    // 指派人单位配置
    const assignedPeopleProjectDataSb = ref([])
    const getAssignedPeopleProjectDataSB = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)

      assignedPeopleProjectDataSb.value = data || []
    }

    // 监听指派人单位
    watch(
      () => assignedPeopleFormSb.companyId,
      newvalue => {
        getAssignedPeopleProjectDataSB(newvalue)
        getProjectData(newvalue)
      }
    )
    // 删除Tag
    const clickTagCloseSb = index => {
      saveSelectDataSb.value.splice(index, 1)
      selectDataSb.value = []
      assignedPeopleTableRefSb.value.clearSelection()
    }

    /** 指派人 结束************************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberDataUserSb = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      saveSelectData: [],
      isSubmie: false
    })
    // 选择指派人列表
    const selectDataSb = ref()
    // 保存选择人
    const saveSelectDataSb = ref([])

    watch(
      () => tabberDataUserSb.saveSelectData,
      newvalue => {
        saveSelectDataSb.value = newvalue
      }
    )
    // 获取账户列表
    const getAssignedPeopleDataSb = async (
      params = { pageIndex: tabberDataUserSb.page, pageSize: tabberDataUserSb.size }
    ) => {
      const searchDataParams = searchParams(assignedPeopleFormSb, ['companyId', 'projectId'])
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetGanagerEngineers',
        params,
        searchDataParams
      )
      tabberDataUserSb.data = data || []
      tabberDataUserSb.total = total || 0
      if (selectDataSb.value.length > 0) {
        selectDataSb.value.map(item => {
          assignedPeopleTableRefSb.value.toggleRowSelection(item, true)
        })
      }
    }
    //分页事件
    watch([() => tabberDataUserSb.page, () => tabberDataUserSb.size], () => {
      getAssignedPeopleDataSb()
    })
    const currentChangeUserSb = page => {
      tabberDataUserSb.page = page
    }
    const sizeChangeUserSb = size => {
      tabberDataUserSb.size = size
    }

    // 重置搜索内容
    const resetSearchDataUserSb = () => {
      assignedPeopleFormRefSb.value.CustomFormRef.resetFields()
      tabberDataUserSb.page === 1 ? getAssignedPeopleDataSb() : (tabberDataUserSb.page = 1)
    }
    // 搜索
    const searchFnUserSb = () => {
      tabberDataUserSb.page === 1 ? getAssignedPeopleDataSb() : (tabberDataUserSb.page = 1)
    }

    // 选中的添加到列表
    const getSelectionSb = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          companyId: item.companyId
        }
      })
    // 选中单个
    const selectSb = selection => {
      selectDataSb.value = getSelection(selection)
    }
    // 选中全部
    const selectAllSb = selection => {
      selectDataSb.value = selectDataSb.value = getSelection(selection)
    }

    // 提交选中指派人
    const clickSubmitAssignedPeopleSb = () => {
      tabberDataUserSb.isSubmie = true
      saveSelectDataSb.value = selectDataSb.value
      assignedPeopleTableFormSb.repairUsers = selectDataSb.value
      assignedPeopleDialogRefSb.value.showDialog = false
    }
    // 取消选择指派人
    const clickSubmitAssignedPeopleCloseSb = () => {
      if (!tabberDataUserSb.isSubmie) {
        selectDataSb.value = []
        assignedPeopleTableRefSb.value.clearSelection()
      }
      tabberDataUserSb.isSubmie = false
    }
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    // 处理设备
    const getEquipmentManageata = async projectId => {
      const projectParams = {
        projectId,
        companyId: assignedPeopleFormSb.companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetDeviceInfos', projectParams)
      equipmentManageata.value = data || []
    }

    // 监听项目
    watch(
      () => assignedPeopleFormSb.projectId,
      newvalue => {
        getEquipmentManageata(newvalue)
      }
    )

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const FILEPREVIEWURLs = ref(`${BASEURL}${FILEPREVIEWURL}`)
    const onSuccess = files => {
      if (files.data) {
        assignedPeopleTableFormSb.attachVos = [
          ...assignedPeopleTableFormSb.attachVos,
          ...files.data
        ]
      }
    }

    const isVideoError = () => {
      showVideo.value = false
    }
    const isVideoSuccess = () => {
      showVideo.value = true
    }

    /* 结束 数据列表 **********************************/

    // 优化指派人 wml 2021-11-08
    const repairUsersDataSb = ref([])
    const repairUsersNodeSb = ref([])
    watch(
      () => repairUsersNodeSb.value,
      newvalue => {
        let nList = []
        newvalue.map(item => {
          if (item.children) {
            item.children.map(v => {
              nList.push(v)
            })
          } else {
            nList.push({
              userName: item.userName,
              userId: item.userId,
              companyId: item.companyId
            })
          }
        })
        assignedPeopleTableFormSb.repairUsers = nList
      }
    )

    const repairUsersData = ref([])
    const repairUsersNode = ref([])
    watch(
      () => repairUsersNode.value,
      newvalue => {
        let nList = []
        newvalue.map(item => {
          if (item.children) {
            item.children.map(v => {
              nList.push(v)
            })
          } else {
            nList.push({
              userName: item.userName,
              userId: item.userId,
              companyId: item.companyId
            })
          }
        })
        assignedPeopleTableForm.repairUsers = nList
      }
    )

    // 收缩
    const isClose = ref(false)
    const isTableClose = ref(false)

    // tab 按钮
    const onClickTab = () => {
      content.emit('on-tab-btn', 1)
    }

    const processData = ref([])
    watch(
      () => assignedPeopleTableFormSb.projectId,
      newValue => {
        processData.value = []
        if (newValue) {
          getProcessData()
        } else {
          // faultServiceCenterForm.processData.definitionId = ''
        }
      }
    )

    const getProcessData = () => {
      let params = {
        companyId: '',
        projectId: assignedPeopleTableFormSb.projectId,
        businessType: 'device_error',
        pageIndex: 1,
        pageSize: 500
      }
      dispatch('fetchProjectProcessPage', params).then(res => {
        if (res.code === 200) {
          processData.value = res.data.records
          const item = processData.value.find(
            d => d.definitionId === assignedPeopleTableFormSb.processData.definitionId
          )
          if (!item) {
            let processDataValue = processData.value[0] || {}
            assignedPeopleTableFormSb.processData.definitionId = processDataValue.definitionId || ''
            assignedPeopleTableFormSb.processData.definitionKey = processDataValue.processKey || ''
            assignedPeopleTableFormSb.processData.businessType = processDataValue.businessType || ''
            assignedPeopleTableFormSb.processData.processName = processDataValue.processName || ''
          }
        }
      })
    }

    const definitionChange = value => {
      if (value) {
        const item = processData.value.find(d => d.definitionId === value)
        assignedPeopleTableFormSb.processData.definitionKey = (item && item.processKey) || ''
        assignedPeopleTableFormSb.processData.businessType = (item && item.businessType) || ''
        assignedPeopleTableFormSb.processData.processName = (item && item.processName) || ''
      } else {
        assignedPeopleTableFormSb.processData.definitionKey = ''
        assignedPeopleTableFormSb.processData.businessType = ''
        assignedPeopleTableFormSb.processData.processName = ''
      }
    }
    const toggleHandle = value => {
      tMapRef.value && tMapRef.value.readTmap(value)
    }
    onMounted(async () => {
      let loading = ElLoading.service({
        lock: true,
        text: '',
        background: 'transparent'
      })
      getDeviceList()
      getDeviceBasesStatisticsVo()
      await getequipmentTreeList()

      // 关闭加载
      loading.close()
      loading = null

      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
    })
    // 销毁前清除一些变量
    onBeforeUnmount(() => {
      clearTimeout(timeOut.value)
    })
    return {
      FILEPREVIEWURLs,
      tabberHeight,
      searchData,
      equipmentTreeList,
      defaultCheckedKeys,
      getequipmentTreeList,
      nodeCLick,
      deviceInfosList,
      equipmentInfoDialogRef,
      equipmentInfoVideoDialogRef,
      equipmentInfo,
      // onequipmentMap,
      tabberData,
      showTabel,
      formetData,
      clickTagClose,
      assignedPeopleFormRef,
      clickAssignedPeopleAdd,
      assignedPeopleTableFormRef,
      assignedPeopleTableForm,
      assignedPeopleTableFormRule,
      clickOrder,
      assignedDialogClose,
      processOption,
      assignedDialogRef,
      tabberDataUser,
      searchFnUser,
      clickSubmitAssignedPeopleClose,
      clickSubmitAssignedPeople,
      selectAll,
      select,
      resetSearchDataUser,
      sizeChangeUser,
      currentChangeUser,
      assignedPeopleForm,
      unitOptions,
      assignedPeopleProjectData,
      assignedPeopleDialogRef,
      assignedPeopleTableRef,
      clickSubmitAssignedPeopleCloseSb,
      clickSubmitAssignedPeopleSb,
      selectAllSb,
      selectSb,
      resetSearchDataUserSb,
      sizeChangeUserSb,
      currentChangeUserSb,
      assignedPeopleFormSb,
      assignedPeopleProjectDataSb,
      assignedPeopleDialogRefSb,
      assignedPeopleTableRefSb,
      assignedPeopleTableFormRefSb,
      assignedPeopleTableFormSb,
      getSelectionSb,
      searchFnUserSb,
      selectDataSb,
      tabberDataUserSb,
      clickTagCloseSb,
      getAssignedPeopleProjectDataSB,
      assignedPeopleFormRefSb,
      clickAssignedPeopleAddSb,
      assignedPeopleTableFormRuleSb,
      assignedDialogRefSb,
      clickOrderSb,
      assignedDialogCloseSb,
      assignedDialogSubmitSb,
      equipmentManageata,
      deviceNode,
      typeData,
      severityData,
      action,
      onSuccess,
      toKen,
      showVideo,
      isVideoError,
      isVideoSuccess,
      videoRef,
      tMapRef,
      tMapCenter,
      userInfo,
      repairUsersDataSb,
      repairUsersNodeSb,
      repairUsersData,
      repairUsersNode,
      clickDetails,
      isClose,
      isTableClose,
      onClickTab,
      folderTreeRef,
      submitBtn,
      tampDeviceId,
      cityLocal,
      isFull,
      downTabBar,
      processData,
      definitionChange,
      toggleHandle
    }
  }
}
</script>

<style lang="scss">
.name-tag-box {
  border: 1px solid $--border-color-base;
  border-radius: 4px;
  padding: 0 5px;
  min-height: 40px;
  max-height: 110px;
  overflow-x: hidden;

  .el-tag {
    margin-right: 5px;
  }
}

.assignedPeople-select .el-input__inner {
  width: 150px;
}

.equipment-layer {
  @extend %params-global;

  .el-tree {
    background: transparent;
  }

  &-tip {
    .el-card__body {
      padding: 0 !important;
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
}

.tmap-box-main.equipment-main {
  position: relative;

  .device-type-bar {
    position: absolute;
    min-width: 100px;
    bottom: 6px;
    right: 6px;
    z-index: 9999;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    > div {
      margin: 0 5px;

      .image-box {
        width: 30px;
        height: auto;

        &.png-img {
          width: 30px;
          height: 30px;
          margin: 10px;
        }

        .image {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        text-align: center;
      }
    }

    &.equipment-logs {
      padding-bottom: 10px !important;

      > div {
        margin: 0 2px;

        .image-box {
          height: auto;
          width: 25px !important;
          margin: auto !important;

          .image {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          font-size: 12px;
          line-height: 25px;
        }
      }
    }
  }
}

.table-box {
  position: absolute;
  right: 0;
  top: 0;
  // width: 1000px;
  z-index: 1000;

  &-close {
    right: inherit;
    left: -20px;
    transform: rotateZ(180deg);
  }

  &-aside-isTableClose {
    right: -498px;

    .tmap-box-aside-close-btn {
      transform: rotate(180deg);
    }
  }

  &-icon {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: $--color-danger;
    color: #fff;
    z-index: 2000;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }
}

.equipmentWindow {
  width: 1200px;
  padding: 0 10px;

  &-title {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
  }

  &-body {
    display: flex;

    > div {
      margin: 10px;
      flex: 1;
    }

    &-video {
      height: 200px;
      line-height: 200px;
      text-align: center;
      background: #d8d8d8;
    }
  }

  &-deviceAttrs {
    flex-wrap: wrap;

    > div {
      width: 46%;
      flex: none;
    }
  }
}

.deviceInfosTab {
  background: transparent !important;

  .el-table__empty-text,
  th,
  tr {
    color: #a1b1c5;
  }

  .el-table__expanded-cell,
  th,
  tr {
    background: #00152899 !important;
  }

  .el-table__body tr:hover > td {
    background-color: transparent;
    color: #fff;
  }

  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #5f6062;
  }
}

.error-table {
  td,
  th.is-leaf {
    border-bottom: 1px solid #5f6062;
  }
}
</style>

<style lang="scss" scoped>
.base-map-toggle-box {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
}
</style>